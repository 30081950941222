var $ = jQuery;

const swiper = new Swiper('.hero-section-custom', {
    slidesPerView: 1.4,
    autoHeight: true,
    loop: true,
    navigation: {
      nextEl: ".swiper-rounded-next",
      prevEl: ".swiper-rounded-prev"
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
      },
      992: {
        slidesPerView: 1.4
      }
    }
});

// const swiper_tabs_new_signs = new Swiper('.swiper_tabs_new_sign', {
//   slidesPerView: 3,
//   autoHeight: true,
//   loop: true,
//   spaceBetween: 20,
//   navigation: {
//     nextEl: ".swiper-rounded-next",
//     prevEl: ".swiper-rounded-prev"
//   },
//   breakpoints: {
//     // when window width is >= 320px
//     320: {
//       slidesPerView: 1,
//     },
//     992: {
//       slidesPerView: 3
//     }
//   }
// });

$( document ).ready(function() {
  // declare Swiper.js .classes
  const myCustomSlider = document.querySelectorAll('.swiper_tabs_new_sign');

  // declare navigation arrows classes
  const mySliderPrev = document.querySelectorAll('.swiper-rounded-prev');
  const mySliderNext = document.querySelectorAll('.swiper-rounded-next');

  for( i=0; i< myCustomSlider.length; i++ ) {
      // add special class with number to each Swiper.js slider and its nabigation arrows  
        myCustomSlider[i].classList.add('swiper_tabs_new_sign-' + i);
        mySliderPrev[i].classList.add('swiper-rounded-prev-' + i);
        mySliderNext[i].classList.add('swiper-rounded-next-' + i);
      
      // Initiate the Sliders
      var vidswiper = new Swiper('.swiper_tabs_new_sign-' + i, {
          // Set sliders parameters
          direction: 'horizontal',
          slidesPerView: 1,
          // spaceBetween: 30,
          autoHeight: true,
          loop: true,
          // Navigation arrows
          navigation: {
              nextEl: '.swiper-rounded-next-' +i,
              prevEl: '.swiper-rounded-prev-' +i,
          },
      });   
  }
});

const swiper_carousel = new Swiper(".logo-carousel", {
    slidesPerView: 6,
    spaceBetween: 50,
    loop: true,
    centeredSlides: false,
    slideActiveClass: "active",
    navigation: {
      nextEl: ".next",
      prevEl: ".prev"
    },
    autoplay: {
      enabled: true,
      delay: 5000
    },
    // Media
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 4,
        spaceBetween: 30
      },
      992: {
        slidesPerView: 6
      }
    }
});
const swiper_mentor = new Swiper('.swiper_mentor', {
  slidesPerView: 1,
  autoHeight: true,
  navigation: {
    nextEl: ".swiper-rounded-next",
    prevEl: ".swiper-rounded-prev"
  }
});

// Animation top block
$('.animation-block a').on('click', function(e) {
  e.preventDefault();
  $('.animation-block').addClass('hide-element');
});

$(function() {
  $(window).on('scroll', function () { 
    $('.animation-block').addClass('hide-element');
   });
});

// Tab
function getUrlVars()
{
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++)
    {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}

var tab = getUrlVars()["tab"];
if((typeof tab !== "undefined")) {
  var tab_link = $('.nav-link[data-tab=brands]'),
      tab_content = $('.tab-pane[data-tab=brands]');

  $('.tab-pane').removeClass('show active');
  $('.nav-link').removeClass('active');
  $(tab_content).addClass('show active');
  $(tab_link).addClass('active');
};

// function getCookie(name) {
//   const value = `; ${document.cookie}`;
//   const parts = value.split(`; ${name}=`);
//   if (parts.length === 2) return parts.pop().split(';').shift();
// }

// if(window.location.pathname === '/') {

// }
// <?php if ( is_front_page() && !isset($_COOKIE['show_animation'])): ?>

// Load more press
$('.dba-in-press .load-more a').on('click', function(e) {
  e.preventDefault();
  var that = this;
  $(this).closest('.dba-in-press').toggleClass('show-all');
  $(this).text(($(that).text() == 'Load More') ? 'Close All' : 'Load More');
});

// To Top
$(document).ready(function () {
  var to_top = document.getElementById("toTop");
  window.onscroll = function() {scrollFunction()};

  function scrollFunction() {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          to_top.classList.add("active");
      } else {
          to_top.classList.remove("active");
      }
  }

  $("a[href='#top']").click(function() {
      $("html, body").animate({ scrollTop: 0 }, "slow");
      return false;
  });
});


$(document).ready(function () {
  $(function() {
    // Javascript to enable link to tab
    var hash = document.location.hash;
    if (hash) {
      console.log(hash);
      // $('.nav-tabs a.nav-link').removeClass('active');
      $('.nav-tabs a[href='+hash+']').tab('show');
    }
  
    // Change hash for page-reload
    $('.news-menu a[href^="#"]').on('click',function (e) {
    
      var target = $(this).attr('href');
      window.location.hash = target;
    
    });
  });
});

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));

// Client
(function(){ var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function(){ acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, disableBgProcess : false, language : 'en', position : 'left', leadColor : '#146ff8', triggerColor : '#146ff8', triggerRadius : '50%', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'small', triggerOffsetX : 20, triggerOffsetY : 20, mobile : { triggerSize : 'small', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerOffsetX : 10, triggerOffsetY : 10, triggerRadius : '50%' } }); }; h.appendChild(s); })(); 